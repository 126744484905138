import fetch from 'auth/FetchInterceptor'

const sessionService = {}

sessionService.getQuestions = function ({sessionId}) {
  return fetch({
    url: `/session/${sessionId}/questions`,
    method: 'get',
    params: {}
  })
}

sessionService.getAnswers = function ({sessionId}) {
  return fetch({
    url: `/session/${sessionId}/answers`,
    method: 'get',
    params: {}
  })
}

sessionService.getSections = function ({sessionId}) {
  return fetch({
    url: `/session/${sessionId}/sections`,
    method: 'get',
    params: {}
  })
}

sessionService.postQuestion = function (data) {
  return fetch({
    url: `/question/${data.examId}/${data.sessionId}`,
    method: 'post',
    data
  })
}

sessionService.postAnswer = function (data) {
  return fetch({
    url: `/answer/${data.examId}/${data.sessionId}/${data.questionId}`,
    method: 'post',
    data
  })
}

sessionService.postSection = function (data) {
  return fetch({
    url: `/section/${data.sessionId}`,
    method: 'post',
    data
  })
}

sessionService.createSession = function (data) {
  return fetch({
    url: `/session/${data.examId}`,
    method: 'post',
    data
  })
}

sessionService.getSession = function ({examId, sessionId}) {
  return fetch({
      url: `/session/${examId}/${sessionId}`,
      method: 'get',
      params: {}
  })
}

export default sessionService