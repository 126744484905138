import fetch from 'auth/FetchInterceptor'


const fileService = {}

fileService.uploadFile = function ({file, examId, sessionId}) {
    const formData = new FormData();
    formData.append('file', file.originFileObj);
    formData.append('directory', `${examId}/${sessionId}`)
    return fetch({
      url: `/file/images`,
      method: 'post',
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
}

export default fileService