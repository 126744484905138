import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fileService from "services/FileService"


export const uploadFile = createAsyncThunk('file/upload', async (data, { rejectWithValue }) => {
    const { file, examId, sessionId } = data
	try {
		const response = await fileService.uploadFile({ file, examId, sessionId })
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const initialState = {
    status: 'idle',
    error: null,
    file: {}
}

export const fileSlice = createSlice({
    name: 'file',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(uploadFile.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(uploadFile.fulfilled, (state, action) => {
            state.status = 'success'
            state.file = action.payload
        })
        .addCase(uploadFile.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
    }
})

export default fileSlice.reducer

export const selectFile = state => state.file.file