import fetch from 'auth/FetchInterceptor'
import { domain } from './ExamService'

const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: '/auth/login',
		method: 'post',
		data: data
	})
}

AuthService.googleLogin = function (data) {
	return fetch({
		url: '/auth/googlelogin',
		method: 'post',
		data: data
	})
}

AuthService.register = function (data) {
	return fetch({
		url: '/auth/register',
		method: 'post',
		data: data
	})
}

AuthService.getUser = function () {
	return fetch({
	  url: `/auth/user`,
	  method: 'get',
	  params: {}
	})
  }

AuthService.getUsers = function () {
return fetch({
	url: `/auth/users`,
	method: 'get',
	params: {}
})
}

AuthService.getRoles = function () {
	return fetch({
		url: `/domain/${domain}/roles`,
		method: 'get',
		params: {}
	})
}

AuthService.updateUserRole = function (data) {
	return fetch({
		url: `/auth/update-role`,
		method: 'post',
		data
	})
}

AuthService.createRole = function (data) {
	return fetch({
		url: `/role/${domain}`,
		method: 'post',
		data
	})
}


AuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post'
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post'
	})
}

export default AuthService;