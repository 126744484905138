import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.exams',
        path: `${APP_PREFIX_PATH}/exams`,
        component: React.lazy(() => import('views/app-views/app/exams'))
    },
    {
        key: 'dashboard.exam',
        path: `${APP_PREFIX_PATH}/exams/:examId`,
        component: React.lazy(() => import('views/app-views/app/exam'))
    },
    {
        key: 'dashboard.session',
        path: `${APP_PREFIX_PATH}/exams/:examId/sessions/:sessionId`,
        component: React.lazy(() => import('views/app-views/app/session'))
    },
    {
        key: 'dashboard.question',
        path: `${APP_PREFIX_PATH}/question/:examId/:sessionId`,
        component: React.lazy(() => import('views/app-views/app/question'))
    },
    {
        key: 'dashboard.answer',
        path: `${APP_PREFIX_PATH}/answer/:examId/:sessionId`,
        component: React.lazy(() => import('views/app-views/app/answer'))
    },
    {
        key: 'dashboard.help',
        path: `${APP_PREFIX_PATH}/help`,
        component: React.lazy(() => import('views/app-views/app/help'))
    },
    {
        key: 'dashboard.users',
        path: `${APP_PREFIX_PATH}/users`,
        component: React.lazy(() => import('views/app-views/app/users'))
    },
    {
        key: 'dashboard.profile',
        path: `${APP_PREFIX_PATH}/profile`,
        component: React.lazy(() => import('views/app-views/app/profile'))
    }
    
]