import React  from 'react'
import { Navigate, Outlet, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig'
import { selectToken } from 'store/slices/authSlice';

const PublicRoute = () => {

	const token = useSelector(selectToken)
	const [searchParams, setSearchParams] = useSearchParams()
  
	return token ? <Navigate to={searchParams.get('redirect') || AUTHENTICATED_ENTRY} /> : <Outlet/>
}

export default PublicRoute