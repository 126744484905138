import fetch from 'auth/FetchInterceptor'

const baseDataService = {}

baseDataService.getSubjects = function ({examId}) {
  return fetch({
    url: `/subject/${examId}/subjects`,
    method: 'get',
    params: {}
  })
}

export default baseDataService 
